import React, { useRef, useState } from 'react';
import '../styles/SliderWithThumbnails.css';

const SliderWithThumbnails = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
  };

  const handleBackClick = () => {
    setCurrentImage((prevImage) => (prevImage === 0 ? images.length - 1 : prevImage - 1));
  };

  const handleNextClick = () => {
    setCurrentImage((prevImage) => (prevImage === images.length - 1 ? 0 : prevImage + 1));
  };

  return (
    <div className="image-slider">
      <div className="images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className={index === currentImage ? 'active' : ''}
            alt={`Slide ${index}`}
          />
        ))}
      </div>
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className={index === currentImage ? 'active' : ''}
            alt={`Thumbnail ${index}`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
      <div className="back-btn" onClick={handleBackClick}>
        <ion-icon name="chevron-back-outline"></ion-icon>
      </div>
      <div className="next-btn" onClick={handleNextClick}>
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>
    </div>
  );
};

export default SliderWithThumbnails;
